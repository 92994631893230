import { STORE_USER } from './actionTypes';


export function applySetUsers(user) {
  return {
    type: STORE_USER,
    payload: {user}
  };
}

export const UserType = {
  id : ''
}
