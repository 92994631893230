import { AppBar, Grid, Hidden, Toolbar, Typography } from "@mui/material";
import React from "react";


function Header() {

  return (
    <React.Fragment>
        <AppBar component="div"
          color="primary"
          position="static"
          elevation={0}
          sx={{ zIndex: 0 }}>
          <Toolbar>
            <Grid container spacing={1} alignItems="center">
              <Grid item xs={8}>
                <Typography color="inherit" variant="h5" component="h1">Gacha Pog</Typography>
              </Grid>
              <Hidden smDown>
              <Grid item xs={4}> <Typography color="inherit" variant="h5" component="h1">JPEG collection center</Typography>
              </Grid>
              </Hidden>
            </Grid>
          </Toolbar>
        </AppBar>
    </React.Fragment>
  );
}

export default Header;
