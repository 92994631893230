import { Container, Typography } from "@mui/material";

function NotFound() {
  return (
    <div>
      <Container>
        <h1>404</h1>
        <Typography color="textPrimary" gutterBottom>You seem lost</Typography>
      </Container>
    </div>
  );
}

export default NotFound;