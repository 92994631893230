import './App.css';
import React from 'react';
import './index.css';
import Home from './pages/Home';
import { Provider } from 'react-redux';
import store from './redux/store';
import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";
import Header from './header/Header'
import About from './pages/About';
import NotFound from './pages/NotFound';
import { ThemeProvider } from '@emotion/react';
import { createTheme, CssBaseline } from '@mui/material';
const theme = createTheme({
  palette: {
    primary: {
      main: '#051622',
      light: '#2d3c4a',
      dark: '#0000000',
      contrastText:"#deb992"
    },
    secondary: {
      main: '#1ba098',
      light: '5dd2c9',
      dark: '00716a',
    },
    contrastThreshold: 3,
    tonalOffset: 0.2,
  }
});

function App() {
  return (
      <ThemeProvider theme={theme}>
        <Provider store={store}>
          <CssBaseline/>
          <BrowserRouter>
            <Header />
            <Routes>
              <Route path="/">
              <Route index element={<Home />}/>
              <Route path="about" element={<About />}/>
              {/* <Route path='data' component={() => window.location = */}
                {/* // 'http://localhost:3000/data'} /> */}
              <Route path="*" element={<NotFound />}/>
              </Route>
            </Routes>
          </BrowserRouter>
        </Provider>
      </ThemeProvider>
  );
}

export default App;
