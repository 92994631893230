import { STORE_USER } from '../actionTypes';
import { UserType } from './../actions';

const initState = {
    user : UserType
};



export default function userReducer(state = initState, action) {
    switch (action.type) {
        case STORE_USER: {
            return {
                ...state,
                users: action.payload
            }
        }
        default:
            return state
    }
}

