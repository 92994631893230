import * as React from 'react';
import { CardMedia, Grid } from "@mui/material";
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import { useState, useEffect } from 'react';
import { Star } from '@mui/icons-material';
import StarBorderIcon from '@mui/icons-material/StarBorder';


function Home() {

  const [info, setInfo] = useState(null
  );

  useEffect(() => {
    fetch((process.env.REACT_APP_GACHA_URL), {
      method: 'GET',
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        console.log(response);
      })
      .then((data => {
        setInfo(data);
      }));
  }, [])

  function starRating(number) {
    if (!number) {
      return (<React.Fragment />);
    }
    let out = [];
    console.log(number);
    for (let i = 0; i < 5; i++) {
      if (number > i) {
        out.push(<Star />);
      }
      else {
        out.push(<StarBorderIcon />);
      }
    }
    return (out);
  }

  function characterCard(input) {
    var media = null;
    if (input && input.image_url && input.rarity) {
      media = <CardMedia image={input.image_url}
        sx={{ width: 256, height: 362 }}
        alt="Live from space album cover" >
        {starRating(input.rarity)}
      </CardMedia>
    }
    return (
      <React.Fragment>
        {media}
        <CardContent>
        </CardContent>
        <CardActions>
          <Button size="small">Learn More</Button>
        </CardActions>
      </React.Fragment>
    )
  };

  function outlinedCard(input) {
    return (
      <Box sx={{
        minWidth: 275, maxWidth: 350,
        boxShadow: "0 5px 8px 0 rgba(0, 0, 0, 0.3)"
      }}>

        <Card variant="outlined" align='center' >{characterCard(input)}</Card>
      </Box>
    );
  }
  
  return (
    <Grid bgcolor="primary.light" align='center'>
      {outlinedCard(info)}
    </Grid>
  );
}

export default Home;
