import { Container, Typography } from "@mui/material";

function About() {
  return (
    <div>
      <Container>
        <h1>About</h1>
        <Typography color="textPrimary" gutterBottom>About: This is a website for gacha fun</Typography>
      </Container>
    </div>
  );
}

export default About;